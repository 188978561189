<template>
  <div v-if="job">
    <h1>{{ job.title }} : Explore Packages</h1>
    <div class="row">
      <div
        v-for="explorePackage in job.explorePackages"
        :key="explorePackage.packageId"
      >
        <div
          class="text-center roundedBox fadeUp delay-50 col-1-2"
          @click="toggleModalPackage(explorePackage.packageId)"
        >
          <img
            class="imgRound imgWidth-200"
            :src="'/img/photos/' + explorePackage.packageSlug + '.jpg'"
            :alt="explorePackage.packageName"
            :title="explorePackage.packageName"
          />
          <div class="packageTitle">{{ explorePackage.packageName }}</div>
        </div>
        <div v-if="this.showModalPackage.includes(true)">
          <div class="clientModalConstrainer">
            <Modal theme="modalClient" @close="toggleModalPackage(-1)">
              <div>
                <img
                  class="imgRound imgWidth-200"
                  :src="'/img/photos/' + this.selectedPackage.packageSlug + '.jpg'"
                  :alt="this.selectedPackage.packageName"
                  :title="this.selectedPackage.packageName"
                />
                <div class="packageTitle">
                  {{ this.selectedPackage.packageName }}
                </div>
                <div v-html="this.selectedPackage.packageContent"></div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading solution details...</p>
  </div>
</template>

<script>
var showModalPackage = new Array(3).fill(false);
var selectedPackage = null;

import Modal from "../../components/Modal.vue";
export default {
  components: { Modal },
  props: ["id"],
  data() {
    return {
      job: null,
      showModalPackage: [3],
    };
  },
  mounted() {
    fetch("/data/db.json")
      .then((res) => res.json())
      .then((data) => (this.job = data["jobs"][this.id]))
      .catch((err) => console.log(err.message));
  },
  methods: {
    toggleModalPackage(key) {
      if (key < 0) {
        this.showModalPackage = new Array(3).fill(false);
      }
      this.explorePackages = this.job.explorePackages[key - 1];
      this.showModalPackage[key - 1] = !showModalPackage[key - 1];
      this.selectedPackage = this.job.explorePackages[key - 1];
    },
  },
};
</script>

<style scoped>
.packageTitle {
  font-size: 24px;
  color: #000;
  font-weight: 900;
  line-height: 3em;
}
.backdrop {
  background: rgba(0, 0, 0, 0.15);
}
</style>